@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;600&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Cinzel', serif;
  transition: 0.2s ease-out;
}
body {
  background-color: rgb(216, 213, 213);
}
